// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-proyecto-js": () => import("./../../src/components/proyecto.js" /* webpackChunkName: "component---src-components-proyecto-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arte-ideas-js": () => import("./../../src/pages/arte-ideas.js" /* webpackChunkName: "component---src-pages-arte-ideas-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-disenio-js": () => import("./../../src/pages/proyectos-disenio.js" /* webpackChunkName: "component---src-pages-proyectos-disenio-js" */),
  "component---src-pages-proyectos-js": () => import("./../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */)
}

